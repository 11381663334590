import { orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  background: #F5F6FA ;

`

export const Button = styled.a`
  background: ${orange.extra} ;
  height: 48px;
  width: 100%;
  border-radius: 8px;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  color: ${white};
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus, &:hover {
    outline: none;
    color: ${white};
  }

`
