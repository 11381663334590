export default [
  {
    image: require('../images/renda_fixa.webp'),
    text: 'Renda Fixa',
    description: 'CDB, LCI, LCA, Poupança, Debêntures, CRI e CRA',
    link: 'https://inter.co/pra-voce/investimentos/renda-fixa/',
    alt: 'Prédios espelhados e com o céu azul',
  },
  {
    image: require('../images/renda_variavel.webp'),
    text: 'Renda Variável',
    description: 'Ações, Fundos Imobiliários e Day Trade',
    link: 'https://inter.co/pra-voce/investimentos/renda-variavel/',
    alt: 'Gráficos de linhas simbolizando oscilações do mercado de renda variável',
  },
  {
    image: require('../images/tesouro_direto.webp'),
    text: 'Tesouro Direto',
    description: 'Tesouro Selic, Prefixado, IPCA e RendA+',
    link: 'https://inter.co/pra-voce/investimentos/renda-fixa/tesouro-direto/',
    alt: 'Prédio espelhados vistos de cima e com luzes acesas',
  },
  {
    image: require('../images/criptomoedas.webp'),
    text: 'Criptomoedas',
    description: 'Bitcoin, Ethereum, Solana e muito mais',
    link: 'https://inter.co/pra-voce/investimentos/criptomoedas/',
    alt: 'Moeda de Bitcoin na cor dourada',
  },
  {
    image: require('../images/fundos_de_investimento.webp'),
    text: 'Fundos de Investimento',
    description: 'Invista nos melhores Fundos com apenas R$ 100,00',
    link: 'https://inter.co/pra-voce/investimentos/fundos-de-investimento/',
    alt: 'Prédios na cor marrom e preto vistos de longe.',
  },
  {
    image: require('../images/previdencia_privada.webp'),
    text: 'Previdência Privada',
    description: 'Garanta sua aposentadoria',
    link: 'https://inter.co/pra-voce/investimentos/previdencia-privada/',
    alt: 'Casal de idosos sentados no deck observado a brisa.',
  },
  {
    image: require('../images/ofertas_publicas.webp'),
    text: 'Ofertas Públicas',
    description: 'Tenha acesso às principais ofertas públicas do mercado.',
    link: 'https://inter.co/pra-voce/investimentos/ofertas-publicas/',
    alt: 'Gráficos simbolizando as oscilações do mercado.',
  },
  {
    image: require('../images/coe.webp'),
    text: 'COE',
    description: 'Certificados de Operações Estruturadas',
    link: 'https://inter.co/pra-voce/investimentos/coe/',
    alt: 'Mãos mexendo no celular visualizando os gráficos de mercado',
  },
]
