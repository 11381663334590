import { orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Button = styled.a`
  background: ${orange.extra};
  border-radius: 8px;
  border: none;
  width: 100%;
  height: 48px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  color: ${white};
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus, &:hover{
    outline: none;
    color: ${white} ;
  }
`
