import React from 'react'

import { widths } from 'src/styles/breakpoints'

import { Button } from './style'
import ImageWebp from 'src/components/ImageWebp'
import useWidth from 'src/hooks/window/useWidth'
const CompreCriptomoedas = () => {
  const width = useWidth(300)
  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-47 fw-700 text-grayscale--500 mb-4 mt-3 mt-md-0'>
              Compre Criptomoedas
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-grayscale--400 mb-4'>
              Explore uma plataforma completa e segura para comprar, vender e negociar Bitcoin, Ethereum e outras moedas digitais, começando a partir de R$1,00.
            </p>
            <Button href='https://inter.co/pra-voce/investimentos/criptomoedas/'>Comprar agora</Button>
          </div>
          <div className='col-12 col-md-6 mt-3 mt-md-0 d-flex justify-content-center align-items-center'>
            {
              width === widths.md ? (
                <ImageWebp
                  altDescription='Nomes das criptomoedas passando por cima do celular.'
                  arrayNumbers={[]}
                  arrayNumbersHeight={[]}
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/investimentos-app-768/image.webp'
                />
              ) : (
                <ImageWebp
                  altDescription='Nomes das criptomoedas passando por cima do celular.'
                  arrayNumbers={[]}
                  arrayNumbersHeight={[]}
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/app-investimento-1440/image.webp'
                />
              )
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default CompreCriptomoedas
