import React from 'react'

import { Card, BoxProdutosInter, Button } from './style'
import ArrowRight from '@interco/icons/build-v4/orangeds/MD/arrow-right'

const OsMlehoresConteudosInvestimento = () => {
  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-20 lh-25 fs-md-24 lh-md-30 fs-lg-32 lh-lg-40 text-center text-grayscale--500 fw-700 mb-4'>
              Os melhores conteúdos sobre investimentos
            </h2>
            <p className='fs-14 lh-16 fs-md-16 lh-md-19 text-center text-grayscale--400'>Esteja atualizado com as novidades do blog e invista com segurança aumentando os seus resultados.</p>
          </div>
          <div className='row'>
            <div className='col-12 col-md-4 col-xl-4'>
              <Card>
                <div className='d-flex mb-2'>
                  <BoxProdutosInter className='fs-12 lh-15 fw-600 text-white bg-orange--extra'>Para simplificar a vida</BoxProdutosInter>
                  <p className='fs-12 lh-15 text-grayscale--500 fw-700 ml-2 mb-0'>Investir</p>
                </div>
                <h3 className='fs-20 lh-24 text-grayscale--500 fw-700 mb-0'>Como começar a investir: melhor guia para iniciantes</h3>
                <p className='fs-12 lh-15 text-grayscale--300 fw-700 mt-2'>20/12/2022</p>
                <p className='fs-14 lh-16 fs-md-16 lh-md-19 text-grayscale--400 mb-2 fw-400'>Este guia completo vai ajudar você a começar a investir do zero...</p>
                <div className='d-flex align-items-center justify-content-end'>
                  <a
                    href='https://blog.inter.co/como-comecar-a-investir/'
                    target='_blank'
                    rel='noreferrer'
                    className='fs-14 lh-16 text-orange--extra fw-400 mb-0 mr-2'
                  >Leia mais
                  </a>
                  <ArrowRight height={24} width={24} color='#FF7A00' />
                </div>
              </Card>
            </div>
            <div className='col-12 col-md-4'>
              <Card>
                <div className='d-flex mb-2'>
                  <BoxProdutosInter>Para simplificar a vida</BoxProdutosInter>
                  <p className='fs-12 lh-15 text-grayscale--500 fw-700 ml-2 mb-0'>Investir</p>
                </div>
                <h3 className='fs-20 lh-24 text-grayscale--500 fw-700 mb-0'>O melhor app de investimento 100% gratuito</h3>
                <p className='fs-12 lh-15 text-grayscale--300 fw-700 mt-2'>18/12/2023</p>
                <p className='fs-16 lh-19'>Faça aportes seguros a partir de R$1,00 e diversifique sua carteira...</p>
                <div className='d-flex align-items-center justify-content-end'>
                  <a
                    href='https://blog.inter.co/melhor-app-investimento/'
                    target='_blank'
                    rel='noreferrer'
                    className='fs-14 lh-16 text-orange--extra fw-400 mb-0 mr-2'
                  >Leia mais
                  </a>
                  <ArrowRight height={24} width={24} color='#FF7A00' />
                </div>
              </Card>
            </div>
            <div className='col-12 col-md-4'>
              <Card>
                <div className='d-flex mb-2'>
                  <BoxProdutosInter>Para simplificar a vida</BoxProdutosInter>
                  <p className='fs-12 lh-15 text-grayscale--500 fw-700 ml-2 mb-0'>Investir</p>
                </div>
                <h3 className='fs-20 lh-24 text-grayscale--500 fw-700 mb-0'>Como investir em dólar simples e rápido</h3>
                <p className='fs-12 lh-15 text-grayscale--300 fw-700 mt-2'>20/09/2022</p>
                <p className='fs-16 lh-19 mb-0'>Descubra as melhores opções para comprar dólar e investir no exterior...</p>
                <div className='d-flex align-items-center justify-content-end'>
                  <a
                    href='https://blog.inter.co/como-investir-em-dolar/'
                    target='_blank'
                    rel='noreferrer'
                    className='fs-14 lh-16 text-orange--extra fw-400 mb-0 mr-2'
                  >Leia mais
                  </a>
                  <ArrowRight height={24} width={24} color='#FF7A00' />
                </div>
              </Card>
            </div>
            <div className='col-12 d-flex justify-content-center mt-4'>
              <Button
                href='https://blog.inter.co/para-simplificar-a-vida/investir/'
                target='_blank'
                rel='noreferrer'
              >Ver mais conteúdos
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OsMlehoresConteudosInvestimento
