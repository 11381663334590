import React, { useState } from 'react'
import { widths } from 'src/styles/breakpoints'
import Qrcode from '../../assets/images/qrcode-home-investimentos.png'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import { Section, Button } from './style'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import dataJSON from './data.json'
import ImageWebp from 'src/components/ImageWebp'
import useWidth from 'src/hooks/window/useWidth'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'
import useDomReady from 'src/hooks/window/useDomReady'
import { Modal } from 'src/components/Modal'

type OMelhorAppCarouselProps = {
  image: string;
  title: string;
  description: string;
  ordenation: string;
}

const OMelhorAppParaInvestir = () => {
  const [ positon, setPosition ] = useState(0)
  const width = useWidth(300)
  const domReady = useDomReady()
  const [ isModalOpen, setIsModalOpen ] = useState(false)

  const dataLayer: IDataLayerParams = {
    section: 'dobra_11',
    element_action: 'click button',
    element_name: 'Abra sua conta',
    section_name: '',
  }

  const cardsModal = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <OpenAccountRightForm
        closeModal={() => setIsModalOpen(false)}
        qrBaixeApp={Qrcode}
        dataLayer={dataLayer}
      />
    </Modal>
  )

  return (
    <Section className='py-5'>
      {cardsModal}
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5 mb-4'>
            <DefaultCarousel
              sm={{ items: 1, slidesToSlide: 1 }}
              md={{ items: 1, slidesToSlide: 1 }}
              lg={{ items: 1, slidesToSlide: 1 }}
              xl={{ items: 1, slidesToSlide: 1 }}
              beforeChange={(nextSlide: number) => setPosition(nextSlide)}
            >
              {
              dataJSON.map((item: OMelhorAppCarouselProps) => (
                <>
                  <h2 className='fs-20 lh-25 fs-md-24 lh-md-30 fs-lg-32 lh-lg-40 fw-700 text-grayscale--500 mb-4' key={item.ordenation}>{item.title}</h2>
                  <h3 className='fs-24 lh-28 fs-lg-40 lh-lg-44 text-grayscale--500 fw-600 mb-4'>{item.ordenation}</h3>
                  <p className='fs-14 fs-lg-16 lh-17 lh-lg-19 text-grayscale--400 fw-400 mb-4'>{item.description}</p>
                </>
                  ))
                }
            </DefaultCarousel>
            <Button
              onClick={() => setIsModalOpen(true)}
            >Abra sua conta
            </Button>
          </div>
          <div className='col-12 col-md-6 d-flex justify-content-center'>
            {
              width < widths.md ? (
                <ImageWebp
                  pathSrc={dataJSON[positon].imageMobile}
                  altDescription={dataJSON[positon].alt}
                  arrayNumbers={[ 294, 315, 422, 542 ]}
                  arrayNumbersHeight={[ ]}
                />
              ) : (
                <ImageWebp
                  pathSrc={dataJSON[positon].image}
                  altDescription={dataJSON[positon].alt}
                  arrayNumbers={[ 294, 315, 422, 542 ]}
                  arrayNumbersHeight={[ ]}
                />
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default OMelhorAppParaInvestir
