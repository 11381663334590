import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const BoxCard = styled.div`
  @media ${device.tablet} {
    border-bottom: none;
    padding: 16px;

    .card-tablet {
      min-height: 196px;
    }
  }

  @media ${device.desktopLG} {
    .card-tablet {
      min-height: 298px;
    }
  }

  @media ${device.desktopXL} {
    .card-tablet {
      min-height: 312px;
    }
  }
`
