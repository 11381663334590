import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const SectionWrapper = styled.section`
  display: flex;
  align-items: center;

  .react-multi-carousel-track {
     padding: 0 0 8px 0;
  }
  
  .react-multi-carousel-dot-list {
    .react-multi-carousel-dot {
      button { 
        background-color: #FF7A00;
      }
    }
  }  

  .react-multiple-carousel__arrow--right {
    svg{
      fill: #FF7A00;
    }
  }

  .react-multiple-carousel__arrow--left {
    svg{
      fill: #FF7A00;
    }
  }

  @media ${device.tablet} {
    .btn {
      max-width: 216px;
    }
  }

  @media ${device.desktopLG} {
    .btn {
      max-width: 356px;
    }
  }
`
