import { orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`


.react-multi-carousel-track {
     padding: 0 0 8px 0;
  }

  .react-multi-carousel-dot-list {
    .react-multi-carousel-dot {
      button {
        background-color: #FF7A00;
      }
    }
  }

  .react-multiple-carousel__arrow--right {
    svg{
      fill: #FF7A00;
    }
  }

  .react-multiple-carousel__arrow--left {
    svg{
      fill: #FF7A00;
    }
  }


`
export const Button = styled.button`
  height: 48px;
  width: 100%;
  background: ${orange.extra};
  border-radius: 8px;
  border: none;
  color: ${white};
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;

  &:focus, &:hover{
    outline: none;
    color: ${white};
  }
`
