import React, { useState } from 'react'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import FerramentasJSON from './data.json'
import { SectionWrapper } from './style'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'
import useDomReady from 'src/hooks/window/useDomReady'
import { Modal } from 'src/components/Modal'
import Qrcode from '../../assets/images/qrcode-home-investimentos.png'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import ImageWebp from 'src/components/ImageWebp'

type FerramentasCarouselProps = {
  image: string;
  title: string;
  description: string;
  alt: string;
}

const arrayNumbers: number[] = [
  250, 224, 252, 272,
]

const AsMelhoresFerramentasParaTraders = () => {
  const domReady = useDomReady()
  const [ sendDatalayerEvent ] = useDataLayer()

  const [ isModalOpen, setIsModalOpen ] = useState(false)

  const dataLayer: IDataLayerParams = {
    section: 'dobra_09',
    section_name: 'As melhores ferramentas para traders',
    element_action: 'click button',
    element_name: 'Quero ser trader',
  }

  const cardsModal = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <OpenAccountRightForm
        closeModal={() => setIsModalOpen(false)}
        qrBaixeApp={Qrcode}
        dataLayer={dataLayer}
      />
    </Modal>
  )
  const ferramentas = FerramentasJSON

  return (
    <SectionWrapper id='as-melhores-ferramentas-para-traders'>
      {cardsModal}
      <div className='container py-5'>
        <div className='row align-items-center justify-content-center'>
          <div className='col-12 col-md-4 col-lg-5 pr-0 pl-md-0 pr-md-3 pl-lg-3 pr-lg-0'>
            <h2 className='fs-24 fs-28 fs-lg-28 lh-lg-33 fs-xl-48 lh-xl-52 text-grayscale--500 fw-700 mb-3'>
              Ferramentas para aumentar seus resultados
            </h2>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-17 lh-lg-19 lh-xl-22 text-grayscale--400 fw-400 mb-0'>
              Opere suas ações com ferramentas automatizadas de forma transparente, ágil e confiável.
            </p>
            <div>
              <a
                title='Quero ser trader'
                className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mt-4 text-md-center fw-700'
                href='https://inter.co/pra-voce/investimentos/mercado-futuro/'
                target='_blank'
                rel='noreferrer'
              >
                Quero ser trader
              </a>
            </div>
          </div>
          <div className='col-12 col-md-8 col-lg-7 col-xl-6 offset-xl-1 offset-xxl-2 mt-2 mt-md-0 px-0'>
            <DefaultCarousel
              sm={{ items: 1, slidesToSlide: 1 }}
              md={{ items: 1, slidesToSlide: 1 }}
              lg={{ items: 1, slidesToSlide: 1 }}
              xl={{ items: 1, slidesToSlide: 1 }}
            >
              {
              ferramentas.map((item: FerramentasCarouselProps, index: number) => (
                <div key={index} className='carousel-card bg-white rounded-3 py-3 pl-2'>
                  <div className='row align-items-center pl-lg-4 pl-xl-0'>
                    <div className='col-12 col-md-6 pb-4 d-flex justify-content-center'>
                      <ImageWebp
                        pathSrc={item.image}
                        altDescription={item.alt}
                        arrayNumbers={arrayNumbers}
                      />
                    </div>
                    <div className='col-12 col-md-6 px-0 pr-md-3'>
                      <h3 className='fs-20 lh-25 fs-md-24 lh-md-30 fs-lg-32 lh-lg-40 text-grayscale--500 fw-600' dangerouslySetInnerHTML={{ __html: item.title }} />
                      <p className='fs-14 fs-lg-16 lh-17 lh-lg-19 text-grayscale--400 fw-400' dangerouslySetInnerHTML={{ __html: item.description }} />
                    </div>
                  </div>
                </div>
              ))
            }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default AsMelhoresFerramentasParaTraders
