import React, { useState } from 'react'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import IcArrowRight from '@interco/icons/build-v4/orangeds/MD/arrow-right'
import cardJson from './assets/card.json'
import useWidth from 'src/hooks/window/useWidth'
import { Modal } from 'src/components/Modal'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import timeDeposit from './image/timeDeposit.png'
import stoks from './image/stocks.png'
import reits from './image/reits.png'
import etfs from './image/etfs.png'
import bonds from './image/bonds.png'
import treasuries from './image/treasuries.png'

// Style
import { Card, Button, LinkMore } from './style'
import { widths } from 'src/styles/breakpoints'

type CardProps = {
  image: string | null;
  link: string;
  description?: string;
  title: string;
  order: number;
  qrCode: string;
  isOpen: boolean;
}

const qrCodesCards = {
  timeDeposit: timeDeposit,
  stocks: stoks,
  reits: reits,
  etfs: etfs,
  bonds: bonds,
  treasuries: treasuries,

}

const InvestimentosNoExterior = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth(300)
  const [ cardList, setCardList ] = useState<CardProps[]>(cardJson)

  const handleOpenModal = (index: number) => {
     const newList = cardList.map((item: CardProps, i: number) => {
       if (i === index) {
         item.isOpen = true
       } else {
         item.isOpen = false
       }
       return item
     })
     setCardList(newList)
  }

  const handleCloseModal = () => {
    setCardList(cardList.map((item: CardProps) => ({ ...item, isOpen: false })))
  }

  const dataLayer: IDataLayerParams = {
    section: 'dobra_01',
    element_action: 'click button',
    element_name: 'Quero conhecer',
    section_name: 'Investimentos que simplificam sua vida',
  }

  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-47 fw-700 text-grayscale--500'>
              Investimentos no exterior
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 text-grayscale--400 mb-4'>
              Invista globalmente e de forma segura em qualquer lugar do mundo com o melhor Super App gratuito!
            </p>
          </div>
          {
            width < widths.md ? (
              <div className='col-12'>
                <DefaultCarousel sm={{ items: 1 }} md={{ items: 2 }} lg={{ items: 3 }} xl={{ items: 3, partialVisibilityGutter: 65 }} itemClass='px-1'>
                  {
                  cardJson.map((item: CardProps) => (
                    <>
                      <Card key={item.title} image={item.image}>
                        <div>
                          <div className='d-flex justify-content-between'>
                            <p className={`fs-20 lh-24 fs-lg-24 lh-lg-28 ${item.image ? 'text-white' : 'text-grayscale--500'} fw-600 mb-2`}>{item.title}</p>
                          </div>
                          <p className={`fs-16 lh-19 ${item.image ? 'text-white' : 'text-grayscale--500'}`}>{item.description}</p>
                        </div>
                        <LinkMore href={item.link} image={item?.image}>
                          Saiba mais
                          <IcArrowRight height={24} width={24} color={`${item.image ? 'white' : '#ff7a00'}`} className='ml-2' />
                        </LinkMore>
                      </Card>
                    </>
                  ))
                }
                </DefaultCarousel>

              </div>

            ) : (
              <div className='row d-flex justify-content-lg-between'>
                {
                  cardList.map((item: CardProps, index: number) => (
                    <>
                      <div key={item.title} className={`col-md-6 px-lg-0 px-xl-3 ${item.image ? 'col-lg-5 col-xl-6' : 'col-lg-3'} order-lg-${item.order} mb-md-3 `}>
                        <Card image={item.image}>
                          <div>
                            <div className='d-flex align-items-center justify-content-between'>
                              <p className={`fs-20 lh-24 fs-lg-24 lh-lg-28 ${item.image ? 'text-white' : 'text-grayscale--500'} fw-600 mb-2`}>{item.title}</p>
                            </div>
                            <p className={`fs-16 lh-19 ${item.image ? 'text-white' : 'text-grayscale--500'}`}>{item.description}</p>
                          </div>
                          {
                          width < widths.md ? (
                            <LinkMore href={item.link} image={item?.image}>
                              Saiba mais
                              <IcArrowRight height={24} width={24} color={`${item.image ? 'white' : '#ff7a00'}`} className='ml-2' />
                            </LinkMore>
                          ) : (

                            <Button
                              image={item?.image}
                              onClick={() => {
                              handleOpenModal(index)
                              sendDatalayerEvent({
                                section: `dobra_03, p_${index + 1}`,
                                element_action: 'click button',
                                element_name: item.title,
                                section_name: 'Dê um up no seu setup gamer e ganhe cashback',
                                redirect_url: item?.link,
                              })
                              }
                            }
                            >
                              Saiba mais
                              <IcArrowRight height={24} width={24} color={`${item.image ? 'white' : '#ff7a00'}`} className='ml-2' />
                            </Button>
                          )
                        }
                        </Card>

                      </div>
                      <Modal isModalOpen={item.isOpen} setIsModalOpen={() => handleCloseModal()} locationToRender={document.body}>
                        <OpenAccountRightForm
                          closeModal={() => handleCloseModal()}
                          qrBaixeApp={qrCodesCards[item.qrCode as keyof typeof qrCodesCards]}
                          dataLayer={dataLayer}
                        />
                      </Modal>
                    </>

                  ))
                }

              </div>
            )
          }

        </div>
      </div>
    </section>

  )
}

export default InvestimentosNoExterior
