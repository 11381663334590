import React from 'react'

// Style
import { Button, MarketComponent, Section } from './style'
import Market from '../_InvistaNasMaioresEmpresas/components/_index'

const InvistaNasMaioresEmpresas = () => {
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 mb-3'>
            <MarketComponent>
              <Market />
            </MarketComponent>
          </div>
          <div className='col-12 col-md-6 col-xl-5 order-md-first order-lg-last'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-47 fw-700 text-grayscale--500 mb-3'>
              Invista nas maiores empresas dos EUA
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 text-grayscale--400'>
              Invista no exterior em ativos negociados na NASDAQ e NYSE com a melhor Plataforma Global de Investimentos.
            </p>
            <Button href='https://inter.co/pra-voce/investimentos/plataforma-global-investimentos/'>Conhecer</Button>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default InvistaNasMaioresEmpresas
