import React, { useState, useEffect } from 'react'
import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidthResize from 'src/hooks/window/useWidth'
import { WIDTH_XL } from 'src/utils/breakpoints'
import { Modal } from 'src/components/Modal'
import Popup from 'src/components/Popup'

// Sections
import {
  InvestimentosQueSimplificamSuaVida,
  TiposDeInvestimento,
  InvestimentosNoExterior,
  InvistaNasMaioresEmpresas,
  VantagensParaQuemInveste,
  MeuPorquinho,
  Simples,
  AParceriaQueVocePrecisa,
  GanhePontosLoop,
  CompreCriptomoedas,
  AsMelhoresFerramentasParaTraders,
  OMelhorAppParaInvestir,
  OsMlehoresConteudosInvestimento,
  PrecisaDeAjuda,
  FAQ,
} from './sections/_index'
import DisclaimerInterInvest from 'src/components/DisclaimerInterInvest'

import { Wrapper } from './style'
import useDomReady from 'src/hooks/window/useDomReady'
import useSessionStorage from 'src/hooks/window/useSessionStorage'
import { TesouroPopup } from './PopUpTesouro/_exit-popup-tesouro'

const InterInvestimentos = () => {
  const domReady = useDomReady()
  const windowWidth = useWidthResize(200)

  const [ sendDatalayerEvent ] = useDataLayer()
  const [ isPopupOpen, setIsPopupOpen ] = useState(false)
  const [ isModalPopupOpen, setIsModalPopupOpen ] = useState(true)
  const [ popup, setPopup ] = useSessionStorage('@hide-inter-popup-investimentos', false)
  const isDesktop = windowWidth > WIDTH_XL

  useEffect(() => {
    !isModalPopupOpen && setPopup(true)
  }, [ isModalPopupOpen ])

  const handleOnMouseLeave = () => {
    setIsPopupOpen(true)
  }

  domReady && document.addEventListener('mouseleave', handleOnMouseLeave)

  const PopupExitPage = domReady && !popup && isDesktop && (
    <Modal isModalOpen={isPopupOpen} setIsModalOpen={setIsModalPopupOpen} locationToRender={document.body}>
      <Popup
        closeModal={() => setIsPopupOpen(false)} setPopup={setPopup}
        pageName='investments' child={<TesouroPopup />}
      />
    </Modal>
  )

  const dataLayer: IDataLayerParams = {
    section: 'dobra_15',
    section_name: 'Disclaimer',
    element_action: 'click button',
    element_name: '',
  }

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {PopupExitPage}
        <InvestimentosQueSimplificamSuaVida />
        <TiposDeInvestimento />
        <InvestimentosNoExterior />
        <InvistaNasMaioresEmpresas />
        <VantagensParaQuemInveste />
        <MeuPorquinho />
        <CompreCriptomoedas />
        <GanhePontosLoop />
        <AsMelhoresFerramentasParaTraders />
        <AParceriaQueVocePrecisa />
        <OMelhorAppParaInvestir />
        <Simples />
        <OsMlehoresConteudosInvestimento />
        <PrecisaDeAjuda />
        <FAQ />
        <DisclaimerInterInvest
          sendDatalayerEvent={sendDatalayerEvent}
          dataLayer={dataLayer}
          operationalLink='https://inter.co/inter-dtvm/'
          section='dobra_16'
        />
      </Layout>
    </Wrapper>
  )
}

export default InterInvestimentos
