import { breakpoints } from 'src/styles/breakpoints'
import styled from 'styled-components'

const vantagens = 'https://central-imagens.bancointer.com.br/images-without-small-versions/investments-dobra-5-desktop/image.webp'
const vantagensMD = 'https://central-imagens.bancointer.com.br/images-without-small-versions/investments-dobra5-tablet/image.webp'
const vantagensLg = 'https://central-imagens.bancointer.com.br/images-without-small-versions/investments-dobra5-1024/image.webp'

export const Section = styled.section`


  @media (min-width: ${breakpoints.md}){
    background: url(${vantagensMD}) no-repeat;
    min-height: 638px;
    background-position-x: right ;
    background-size: contain;
  }

  @media (min-width: ${breakpoints.lg}){
    background: url(${vantagensLg}) no-repeat;
    min-height: 688px;
    background-position-x: right ;
  }

  @media (min-width: ${breakpoints.xl}){
    background: url(${vantagens}) no-repeat;
    min-height: 836px;
    background-position-x: right ;
  }

`
