import React from 'react'

import { Section, Card, Button, ButtonReverse } from './style'

import ImageWebp from 'src/components/ImageWebp'
import { widths } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'
const AParceriaQueVocePrecisa = () => {
  const width = useWidth(300)
  return (
    <Section
      role='Pessoa simpática sorrindo e visualizando no laptop os investimentos do Inter'
      className='d-flex align-items-center justify-content-end'
    >
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-end'>
          {
            width < widths.md && (
              <div className='col-12'>
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/investments-dobra-7-mobile/image.webp'
                  altDescription='Pessoa simpósca sorrindo e visualizando no laptop os investimentos do Inter'
                  className='mb-3'
                />
                <h2 className='fs-24 lh-28 fs-lg-28 lh-lg-38 fs-xl-40 lh-xl-50 text-grayscale--500 mb-4 fw-700'>
                  A parceria que você precisa, sempre ao seu lado
                </h2>
                <p className='fs-14 lh-17 fs-md-16 lh-md-19 fs-xl-18 lh-xl-21 text-grayscale--400 mb-5'>
                  Invista com autonomia e segurança, e ainda aproveite um relacionamento personalizado, de acordo com o seu perfil.
                </p>
                <Button href='https://inter.co/pra-voce/relacionamento/inter-black/' target='_blank' rel='noreferrer'>Quero ser Inter Black</Button>
                <ButtonReverse href='https://inter.co/pra-voce/relacionamento/inter-one/' target='_blank' rel='noreferrer'>Quero ser Inter One</ButtonReverse>
              </div>
            )
          }
          {
            width >= widths.md && (
              <div className='col-12 col-md-6'>
                <Card>
                  <h2 className='fs-md-24 lh-md-28 fs-lg-28 lh-lg-38 fs-xl-40 lh-xl-50 text-grayscale--500 fw-700'>
                    A parceria que você precisa, sempre ao seu lado
                  </h2>
                  <p className='fs-14 lh-17 fs-md-16 lh-md-19 fs-xl-18 lh-xl-21 text-grayscale--400 mb-4'>
                    Invista com autonomia e segurança, e ainda aproveite um relacionamento personalizado, de acordo com o seu perfil.
                  </p>
                  <Button
                    href='https://inter.co/pra-voce/relacionamento/inter-black/'
                    target='_blank'
                    rel='noreferrer'
                  >Quero ser Inter Black
                  </Button>
                  <ButtonReverse
                    href='https://inter.co/pra-voce/relacionamento/inter-one/'
                    target='_blank'
                    rel='noreferrer'
                  >Quero ser Inter One
                  </ButtonReverse>
                </Card>
              </div>
            )
          }
        </div>
      </div>
    </Section>
  )
}

export default AParceriaQueVocePrecisa
