import { breakpoints } from 'src/styles/breakpoints'
import styled, { css } from 'styled-components'
import { orange, white } from 'src/styles/colors'

type ImageProsp = {
  image: string | null;
}

export const Section = styled.section`
  background: #FDF8EE ;

  .react-multiple-carousel__arrow--right {
    right: 0
  }

  .react-multiple-carousel__arrow--left {
    left: 0;
  }

  .react-multi-carousel-dot {
    button{
      background-color: #FFDAB7 !important;
    }
  }

  .react-multi-carousel-dot--active {
    button{
      background-color: #FF8700 !important;
    }
  }

`
export const Card = styled.div`
  height: 196px;
  width: 100%;
  border-radius: 16px;
  padding: 24px;
  border: none;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${(props: ImageProsp) => props.image ? `url(${props.image})` : '#FDF8EE'};
  background-size: cover;


  @media (min-width: ${breakpoints.md}){
    ${(props: ImageProsp) => props.image && css`
        height: 346px;
    `}
  }

  @media (min-width: ${breakpoints.lg}){
    /* width: 276px; */
    height: 352px;
  }

  @media (min-width: ${breakpoints.xl}){
    height: 332px;

  }

`
export const Button = styled.button`
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  color: ${(props: ImageProsp) => props.image ? '#ffffff' : `${orange.base}`} !important;
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: end;
  align-items: center;
  background: none ;


  &:focus, &:hover {
    outline: none;
    color: ${white};
  }
`

export const LinkMore = styled.a`
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  color: ${(props: ImageProsp) => props.image ? '#ffffff' : `${orange.base}`} !important;
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: end;
  align-items: center;
  background: none ;


  &:focus, &:hover {
    outline: none;
    color: ${white};
  }
`

export const Tag = styled.div`
  background: #ff7a00;
  color: ${white};
  border-radius: 15px;
  height: 19px;
  width: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  line-height: 13px;
  font-weight: 600;

`
