import { breakpoints } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Card = styled.div`
  background: #F5F6FA;
  border-radius: 16px;
  height: 224px;
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.md}){
    height: 306px;
    padding: 14px;
  }

  @media (min-width: ${breakpoints.lg}){
    height: 243px;
  }

`
export const BoxProdutosInter = styled.div`
  background: ${orange.extra} ;
  border: none;
  border-radius: 4px;
  padding: 4px;
  color: ${white};
  font-weight: 700;
  font-size:  12px;
  line-height: 14px;
  width: 156px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;



`
export const Button = styled.a`
  background: none;
  border: 1px solid ${orange.extra};
  color: ${orange.extra} ;
  border-radius: 8px;
  height: 48px;
  width: 214px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;


 &:focus, &:hover{
    background: ${orange.extra};
    color: ${white};
    outline: none;
  }

`
