import React from 'react'

import Investments from '@interco/icons/build-v4/orangeds/MD/investments'
import Credit from '@interco/icons/build-v4/orangeds/MD/credit'

import { grayscale } from 'src/styles/colors'
import { BoxCard } from './style'
import Icon from 'src/components/UI/MarkdownIcon'
import * as URLs from '../../../../../config/api/Urls'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const Simples = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section id='e-simples-fazer-a-portabilidade-para-o-inter' className='bg-grayscale--100 py-5'>
      <div className='container py-xl-5'>
        <div className='row align-items-center justify-content-lg-between'>
          <div className='col-12 col-md-6 col-lg-5 mb-lg-3'>
            <h2 className='fs-24 lh-28 fs-lg-32 lh-lg-37 fs-xl-40 lh-xl-50 fw-700 text-grayscale--500'>
              É simples fazer a portabilidade para o Inter
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-grayscale--400 mb-3 mb-md-0'>
              Portabilidade de Renda Fixa, Renda Variável nacional e internacional, Tesouro Direto, Fundos de
              Investimento e Previdência Privada.
            </p>
          </div>
          <div className='col-12 col-md-6 pl-md-0'>
            <div className='d-lg-flex'>
              <BoxCard className='col-12 col-lg-6 py-2 py-lg-0 px-0 mr-lg-4'>
                <div className='d-flex flex-column p-3 bg-white border rounded-4 justify-content-center mude-pro-nter'>
                  <div className='mb-3'>
                    <Investments width='24px' height='24px' color={grayscale[500]} />
                  </div>
                  <h3 className='fs-16 lh-20 fs-xl-20 lh-xl-26 fw-600 text-grayscale--500 mb-2 my-lg-3'>
                    Portabilidade de Investimentos
                  </h3>
                  <div className='col-12 mt-2'>
                    <a
                      href='https://inter.co/portabilidade-de-investimentos/'
                      target='_blank'
                      rel='noreferrer'
                      className='fs-14 fw-700 text-orange--extra float-right'
                      onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_06',
                        section_name: 'É simples fazer a portabilidade para o Inter',
                        element_action: 'click button',
                        element_name: 'Saiba mais sobre Portabilidade de Investimentos',
                        redirect_url: URLs.SITE_BASE_URL + '/portabilidade-de-investimentos/',
                      })
                      }}
                    >
                      Saiba mais
                      <Icon width='20' height='20' color='orange--base ml-2' icon='navigation/arrow-right' directory='v2' />
                    </a>
                  </div>
                </div>
              </BoxCard>
              <BoxCard className='col-12 col-lg-6 py-2 py-lg-0 px-0'>
                <div className='d-flex flex-column p-3 bg-white border rounded-4 justify-content-center mude-pro-nter'>
                  <div className='mb-3'>
                    <Credit width='24px' height='24px' color={grayscale[500]} />
                  </div>
                  <h3 className='fs-16 lh-20 fs-xl-20 lh-xl-26 fw-600 text-grayscale--500 mb-2 my-lg-3'>
                    Portabilidade de Previdência Privada
                  </h3>
                  <div className='col-12 mt-2'>
                    <a
                      href='https://inter.co/portabilidade/#portabilidade-de-previdencia-privada'
                      target='_blank'
                      rel='noreferrer'
                      className='fs-14 fw-700 text-orange--extra float-right'
                      onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_06',
                        section_name: 'É simples fazer a portabilidade para o Inter',
                        element_action: 'click button',
                        element_name: 'Saiba mais sobre Portabilidade de Previdência Privada',
                        redirect_url: URLs.SITE_BASE_URL + '/portabilidade/#portabilidade-de-previdencia-privada',
                      })
                      }}
                    >

                      Saiba mais
                      <Icon width='20' height='20' color='orange--base ml-2' icon='navigation/arrow-right' directory='v2' />
                    </a>
                  </div>
                </div>
              </BoxCard>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Simples
