import styled from 'styled-components'
import { orange, white } from 'src/styles/colors'
import invistaNasMelhoresEmpresas from './assets/image/invistaNasMelhoresEmpresas.png'
import { breakpoints } from 'src/styles/breakpoints'
import invistaNasMelhoresEmpresasMD from './assets/image/invistaNasMelhoresEmpresasMd.png'

export const Section = styled.section`
  background-image: url(${invistaNasMelhoresEmpresas});
  background-color: #F5F6FA ;
  background-repeat: no-repeat;
  background-position-y: 214px;
  background-size: contain ;

  @media (min-width: ${breakpoints.md}){
    background-image: none;
  }

`

export const Button = styled.a`
  background: ${orange.extra};
  height: 48px;
  width: 100%;
  border: none;
  border-radius: 8px;
  color: ${white};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  font-weight: 600;

  &:focus, &:hover {
    outline: none;
    color:  ${white};
  }

`
export const MarketComponent = styled.div`
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-position: center;

  @media (min-width: ${breakpoints.md}){
    background: url(${invistaNasMelhoresEmpresasMD}) no-repeat;
    background-size: contain;
    background-position-y: center;
  }

  @media (min-width: ${breakpoints.lg}){
    background: url(${invistaNasMelhoresEmpresas}) no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-position: center;
    background-size: contain;
  }

  .tradingview-widget-container__widget{

    div {

      @media (min-width: ${breakpoints.xl}){
        width: 400px !important;
        height: 510px !important;
      }
    }
  }

`
