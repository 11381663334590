import React, { useEffect } from 'react'

const HotListWidget = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-hotlists.js'
    script.async = true
    script.innerHTML = JSON.stringify({
      colorTheme: 'light',
      dateRange: '1M',
      exchange: 'NASDAQ',
      showChart: true,
      locale: 'br',
      largeChartUrl: '',
      isTransparent: false,
      showSymbolLogo: true,
      showFloatingTooltip: true,
      width: '300',
      height: '450',
      plotLineColorGrowing: 'rgba(255, 152, 0, 1)',
      plotLineColorFalling: 'rgba(255, 152, 0, 1)',
      gridLineColor: 'rgba(240, 243, 250, 0)',
      scaleFontColor: 'rgba(19, 23, 34, 1)',
      belowLineFillColorGrowing: 'rgba(249, 203, 156, 0.12)',
      belowLineFillColorFalling: 'rgba(246, 178, 107, 0.12)',
      belowLineFillColorGrowingBottom: 'rgba(246, 178, 107, 0)',
      belowLineFillColorFallingBottom: 'rgba(246, 178, 107, 0)',
      symbolActiveColor: 'rgba(249, 203, 156, 0.12)',
    })

    document.querySelector('.tradingview-widget-container__widget').appendChild(script)

    return () => {
      document.querySelector('.tradingview-widget-container__widget').removeChild(script)
    }
  }, [])

  return (
    <div className='tradingview-widget-container'>
      <div className='tradingview-widget-container__widget' />
      <div className='tradingview-widget-copyright'>
        <a href='https://br.tradingview.com/' rel='noopener nofollow noreferrer' target='_blank'>
          <span className='blue-text' />
        </a>
      </div>
    </div>
  )
}

export default HotListWidget
