import React from 'react'
import pageContext from '../../pageContext.json'
import Faq from 'src/components/Faq'

export default function FAQ () {
  return (
    <section id='faq' className='py-5'>
      <div className='container pb-4 py-xl-5'>
        <h2 className='fs-32 lh-40 fs-xl-40 lh-xl-50 text-grayscale--400 fw-600'>Perguntas frequentes</h2>
        <div className='row mb-xl-3'>
          <Faq answerData={pageContext.structuredData.faq} columns={{ sm: 1, md: 1, lg: 1, xl: 1 }} />
        </div>
      </div>
    </section>
  )
}
