import React from 'react'
import { WrapperPopUp, ImageAbsolute } from '../style'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

export function TesouroPopup () {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <WrapperPopUp className='row align-items-center'>
      <ImageAbsolute>
        <ImageWebp
          pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/tesouro-premiado/image.webp'
          altDescription='Total de 220 mil em prêmios. 12 prêmios de 10 mil reais e um super prêmio de 100 mil reais.!'
          arrayNumbers={[ 350, 350, 350, 350 ]}
        />
      </ImageAbsolute>
      <div className='col-6 offset-6'>
        <ImageWebp
          pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/tag-tesouro-premiado/image.webp'
          altDescription='Tesouro Premiado'
          arrayNumbers={[ 150, 150, 150, 150 ]}
          className='mb-3'
        />
        <p className='new-orange fs-46 lh-40'>Transforme seus sonhos em realidade!</p>
        <a
          href='https://inter.co/tesouro-premiado/?utm_source=bancointer&utm_medium=lp&utm_campaign=20240223_Investimentos_TesouroDireto_LPPopUpTesouroPremiado'
          className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mt-4 text-md-center fw-700 w-100 mw-100'
          onClick={() => {
          sendDatalayerEvent({
            section: 'dobra_01',
            element_action: 'click button',
            section_name: '',
            element_name: 'Participe agora!',
            redirect_url: 'https://inter.co/tesouro-premiado/?utm_source=bancointer&utm_medium=lp&utm_campaign=20240223_Investimentos_TesouroDireto_LPPopUpTesouroPremiado&_gl=1*1ppa3ho*_gcl_au*MjAyMDQ1MzIxMi4xNzE0NDAzMzc4',
          })
          }}
        >
          Participe agora!
        </a>
      </div>
    </WrapperPopUp>
  )
}
