import { breakpoints } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'
import styled from 'styled-components'

const background = 'https://central-imagens.bancointer.com.br/images-without-small-versions/investments-dobra-7-desktop/image.webp'
const backgroundMd = 'https://central-imagens.bancointer.com.br/images-without-small-versions/investments-dobra6-768/image.webp'
const backgroundLg = 'https://central-imagens.bancointer.com.br/images-without-small-versions/investments-dobra6-1024/image.webp'

export const Section = styled.section`
  background-repeat: no-repeat;
  min-height: 538px;


  @media (min-width: ${breakpoints.md}){
    background: url(${backgroundMd});
    background-repeat: no-repeat;
    min-height: 613px;
    background-size: contain;
  }

  @media (min-width: ${breakpoints.lg}){
    background: url(${backgroundLg});
    background-repeat: no-repeat;
    min-height: 517px;
    background-size: contain;
  }

  @media (min-width: ${breakpoints.xl}){
    background: url(${background});
    background-repeat: no-repeat;
    min-height: 613px;
    background-size: contain;
  }

  @media (min-width: ${breakpoints.xxl}){
    background-size: cover;
    min-height: 757px;
  }

`
export const Card = styled.div`
  background: ${white};
  border-radius: 24px;
  padding: 24px;
  width: 100%;


  @media (mib-width: ${breakpoints.md}){
    height: 358px;
  }

  @media (mib-width: ${breakpoints.xl}){
    height: 420px;
  }
`

export const Button = styled.a`
  height: 48px;
  width: 100%;
  background: ${orange.extra} ;
  color: ${white};
  border-radius: 8px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-bottom: 16px;

  &:focus, &:hover{
    outline: none;
    color: ${white};
  }

`
export const ButtonReverse = styled.a`
  height: 48px;
  width: 100%;
  background: ${white} ;
  color: ${orange.extra};
  border-radius: 8px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${orange.extra};

  &:focus, &:hover{
    outline: none;
    color: ${orange.extra};
  }

`
