import React from 'react'

import canaisJSON from './assets/data/canais.json'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { SectionCVM, CardLink } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type canaisJSONprops = {
  title: string;
  subtitle: string;
  link: string;
}

const PrecisaDeAjuda = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionCVM className='py-5 bg-grayscale--100' id='precisa-de-ajuda'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-5 pr-xl-0'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-700 text-grayscale--500 '>
              Precisa de ajuda?
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-grayscale--500'>
              Além de falar com a gente pelos nossos
              <a
                href='https://inter.co/canais-de-atendimento/'
                target='_blank'
                rel='noreferrer'
                className='fw-700 text-orange--medium'
              >
                {' '}canais de atendimento
              </a>, você também pode entrar em contato com a CVM e com a BSM.
            </p>
          </div>
          <div className='col-12 col-md-6 col-lg-5 col-xl-6 offset-lg-2 offset-xl-1'>
            {
              canaisJSON.map((item: canaisJSONprops) => (
                <CardLink key={item.title}>
                  <div className='mr-4'>
                    <OrangeIcon size='MD' icon='link' color='#ff7a00' />
                  </div>
                  <div>
                    <h3 className='fs-18 lh-22 mb-4 m-md-0 fw-700 text-grayscale--500'>{item.title}</h3>
                    <a
                      href={item.link}
                      title={item.subtitle}
                      target='_blank'
                      rel='noreferrer'
                      className='fs-16 lh-19 fw-700 text-orange--medium'
                      onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_13',
                          section_name: 'Precisa de ajuda?',
                          element_action: 'click banner',
                          element_name: item.title,
                          redirect_url: item.link,
                         })
                      }}
                    >{item.subtitle}
                    </a>
                  </div>
                </CardLink>
              ))
            }
          </div>
        </div>
      </div>
    </SectionCVM>
  )
}

export default PrecisaDeAjuda
